import english from "../../resources/locales/en/common.json";
import portuguese from "../../resources/locales/pt-br/common.json";

const languages = ["en", "pt-BR"] as const;
export const supportedLanguages = [...languages];
export type Language = (typeof languages)[number];

export type Resource = {
  common: typeof english | typeof portuguese;
};

export const resources: Record<Language, Resource> = {
  en: {
    common: english,
  },
  "pt-BR": {
    common: portuguese,
  },
};

export const returnLanguageIfSupported = (
  lang?: string
): Language | undefined => {
  if (supportedLanguages.includes(lang as Language)) {
    return lang as Language;
  }
  return undefined;
};
